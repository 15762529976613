import { ElConfigProvider } from 'element-plus';
import locale from 'element-plus/dist/locale/zh-cn';
export default {
  name: 'App',
  components: {
    ElConfigProvider
  },
  setup() {
    return {
      locale
    };
  }
};
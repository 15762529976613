import { createStore} from "vuex";
export default createStore({
    state: {
        token: ''
    },
    mutations: {
        SET_TOKEN(state, val) {
            state.token = val
            localStorage.setItem('wid_gets_token', val)
        }
    },
    actions: {}
})

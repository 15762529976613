import { createRouter, createWebHashHistory } from "vue-router";

export const asyncData = []
export const constRoute = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../view/login')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/view/home')
    }
]

const router = createRouter({
    routes: constRoute,
    history: createWebHashHistory()
})

export default router

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import store from './store'
import './assets/css/index.scss'
import 'element-plus/es/components/message/style/css'
createApp(App)
    .use(router)
    .use(store)
    .mount('#app')
